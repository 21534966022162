// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const roleSlice = createSlice({
  name: "role",
  initialState: {
    role: null,
    permissions: []
  },
  reducers: {
    setPermissions: (state, action) => {
      const {role, permissions} = action.payload
      state.role = role
      state.permissions = permissions
    }
  }
})

export const { setPermissions } = roleSlice.actions

export default roleSlice.reducer