// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import employees from '@src/pages/user/employee/store'
import users from '@src/pages/user/store'
import permissions from '@src/pages/permissions/store'
import jobs from '@src/pages/jobs/store'
import role from './role'

const rootReducer = {
  auth,
  users,
  employees,
  permissions,
  jobs,
  role,
  navbar,
  layout
}

export default rootReducer
