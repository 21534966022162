import { initializeApp } from "firebase/app"
import { getFirestore} from "firebase/firestore"
import { getStorage } from 'firebase/storage'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth"
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMANIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

// Initialize Firebase
const firebase = initializeApp(firebaseConfig)
const firestore = getFirestore(firebase)
const storage = getStorage(firebase)
const firebaseAuth = getAuth(firebase)

const emailSignIn = (email, password) => {
  return signInWithEmailAndPassword(firebaseAuth, email, password)
}

const onAuthStateChangedHandler = (observer, errorHandler = null, completeHandler = null) => {
  return onAuthStateChanged(firebaseAuth, observer, errorHandler, completeHandler)
}

export { firebase, firestore, storage, firebaseAuth, emailSignIn, signOut, onAuthStateChangedHandler }
