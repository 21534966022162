// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// Import Firebase
import { collection, query, where, doc, updateDoc, getDocs, getDoc, FieldValue, arrayUnion } from 'firebase/firestore'
import { firestore, firebaseAuth } from "@src/firebase"

export const getRoles = createAsyncThunk("permissions/getRoles", async (_, {getState}) => {
  const tenantId = getState().auth.userData.tenant_id
  const snapshot = await getDoc(doc(firestore, 'tenantIDs', tenantId))
  if (snapshot.exists()) {
    const data = snapshot.data()
    return data.roles ?? []
  }

  return []
})

export const getSuperPermissions = createAsyncThunk("permissions/getSuperPermissions", async () => {
  const snapshots = await getDocs(query(collection(firestore, 'permissions')))
  return snapshots.docs.map(doc => {
    const data = doc.data()
    return {
      id: doc.id,
      ...data
    }
  })
}) 

export const createRole = createAsyncThunk("permissions/createRole", async (role, {getState, dispatch}) => {
  try {
    const userState = getState().auth
    const tenantId = userState.userData.tenant_id
    await updateDoc(doc(firestore, 'tenantIDs', tenantId), {
      roles: arrayUnion(role)
    })
    MySwal.fire({
      title: 'Success!',
      text: 'New Role has been created successfully!',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    })
    dispatch(getRoles())
  
  } catch (e) {
    console.log(e)
    MySwal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  }
})

export const updateRole = createAsyncThunk("permissions/updateRole", async (roles, {getState, dispatch}) => {
  try {
    const userState = getState().auth
    const tenantId = userState.userData.tenant_id
    await updateDoc(doc(firestore, 'tenantIDs', tenantId), {
      roles
    })
    MySwal.fire({
      title: 'Success!',
      text: 'Role has been updated successfully!',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    })
    dispatch(getRoles())
  } catch (e) {
    console.log(e)
    MySwal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  }
})

export const appPermissionsSlice = createSlice({
    name: "permissions",
    initialState: {
      roles: [],
      superPermissions: [],
      loading: true
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getRoles.fulfilled, (state, action) => {
          state.roles = action.payload
          state.loading = false
        })
        .addCase(getSuperPermissions.fulfilled, (state, action) => {
          state.superPermissions = action.payload
          state.loading = false
        })
        .addCase(createRole.pending, (state, action) => {
          state.loading = true
        })
        .addCase(createRole.fulfilled, (state, action) => {
          state.loading = false
        })
        .addCase(updateRole.pending, (state, action) => {
          state.loading = true
        })
        .addCase(updateRole.fulfilled, (state, action) => {
          state.loading = false
        })
    }
})

export default appPermissionsSlice.reducer
