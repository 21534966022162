// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// Import Firebase
import { collection, query, where, doc, updateDoc, getDocs, getDoc, deleteDoc } from 'firebase/firestore'
import { firestore, firebaseAuth } from "@src/firebase"

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useNavigate } from "react-router-dom"

const API_BASEURL = process.env.REACT_APP_USER_FUNCTION_URL

const MySwal = withReactContent(Swal)

export const setUser = createAction('employees/setUser')
export const modifyUser = createAction('employees/updateUser')
export const removeUser = createAction('employees/removeUser')
export const resetUser = createAction('employees/resetUser')

export const getData = createAsyncThunk('employees/getData', async (options, params) => {
  try {
    const queryConstrants = []
    const {tenant_id, role, status, contractType, search } = options
    if (role && role !== '') {
      queryConstrants.push(where("custom_claims.user_role", "==", role))
    }
    if (status && status !== '') {
      queryConstrants.push(where('disabled', '==', !status))
    }
    if (contractType && contractType !== '') {
      queryConstrants.push(where('user_type', '==', contractType))
    }

    const q = query(
      collection(firestore, 'userIDs'),
      where("tenant_id", "==", tenant_id),
      ...queryConstrants
    )

    const snapshots = await getDocs(q)
    const data = snapshots.docs.map(doc => {
      const d = doc.data()
      return {...d, fullName: `${d.Firstname} ${d.Lastname}`, id: doc.id }
    }).filter(user => user.custom_claims?.user_role !== "tenant_admin")

    const filtered = data.filter(user => user.fullName.toLowerCase().includes(search.toLowerCase()))
    return {
      data: filtered,
      total: filtered.length,
      params: options
    }
  } catch (err) {
    console.log("getData:", err)
  }
  
}) 

export const getUser = createAsyncThunk('employees/getUser', async (id, {getState}) => {
    const _doc = await getDoc(doc(firestore, 'userIDs', id))
    if (_doc.exists()) {
        return {..._doc.data(), id }
    } 
    return null
})

export const addUser = createAsyncThunk('employees/addUser', async (user, { dispatch, getState }) => {
  try {
    const token = await firebaseAuth.currentUser.getIdToken()
    await axios.post(`${API_BASEURL}/create_update`, user, {headers : {
      Authorization: `Bearer ${token}`
    }})
    MySwal.fire({
      title: 'Success!',
      text: 'New user has been added successfully!',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    })
  } catch (e) {
    console.log(e)
    MySwal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  }
  // await dispatch(getData(getState().employees.params))
  return user
})

export const updateUser = createAsyncThunk('employees/updateUser', async (user, { dispatch, getState }) => {
  try {
    const token = await firebaseAuth.currentUser.getIdToken()
    await axios.post(`${API_BASEURL}/create_update`, user, {headers : {
      Authorization: `Bearer ${token}`
    }})
    MySwal.fire({
      title: 'Success!',
      text: 'User has been updated successfully!',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    })
    return user
  } catch (e) {
    console.log(e)
    MySwal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
    return null
  }
  
})

export const changeEmail = createAsyncThunk('employees/changeEmail', async (params, {dispatch, getState}) => {
  try {
    const token = await firebaseAuth.currentUser.getIdToken()
    await axios.post(`${API_BASEURL}/update_email`, params, {headers : {
      Authorization: `Bearer ${token}`
    }})
    MySwal.fire({
      title: 'Success!',
      text: 'User email has been updated successfully!',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    })
    return params.new_email
  } catch (err) {
    console.log(e)
    
    MySwal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })

    return null
  }

})

export const deleteUser = createAsyncThunk('employees/deleteUser', async (options, { dispatch, getState }) => {
  const { email, id, callback } = options
  const confirm = await MySwal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-danger ms-1'
    },
    buttonsStyling: false
  })
  if (confirm.value) {
      const token = await firebaseAuth.currentUser.getIdToken()
      try {
        await axios.post(`${API_BASEURL}/delete_user`, { email }, {headers : {
          Authorization: `Bearer ${token}`
        }})
      } catch (error) {
        console.log("Delete user Auth error:")
      }
      try {
        await deleteDoc(doc(firestore, "userIDs", id))
      } catch (e) {
        console.log("Remove user docs error!")
      }
      await MySwal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'User has been deleted.',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      if (callback) {
        callback()
      }
      return true
  } else if (confirm.dismiss === MySwal.DismissReason.cancel) {
    return false
  }
})

export const changePassword = createAsyncThunk('employees/changePassword', async (options, {dispatch, getState}) => {
  const confirm = await MySwal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-danger ms-1'
    },
    buttonsStyling: false
  })
  if (confirm.value) {
    try {
      const token = await firebaseAuth.currentUser.getIdToken()
      await axios.post(`${API_BASEURL}/change_password`, options, {headers : {
        Authorization: `Bearer ${token}`
      }})

      await MySwal.fire({
        icon: 'success',
        title: 'Password Changed!',
        text: 'User Password has been changed!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })

      return true
    } catch (e) {
      MySwal.fire({
        title: 'Error!',
        text: 'Something went wrong!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      })
    }
  } else if (confirm.dismiss === MySwal.DismissReason.cancel) {
    return false
  }
})

export const appUsersSlice = createSlice({
  name: 'employees',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetUser, (state, action) => {
        state.data = []
      })
      .addCase(setUser, (state, action) => {
        state.data = [...state.data, action.payload]
        state.total = state.total + 1
      })
      .addCase(modifyUser, (state, action) => {
        const data = action.payload
        const index = state.data.findIndex(d => d.id === data.id)
        if (index > -1) {
          const copyOfData = [...state.data]
          copyOfData[index] = action.payload
          state.data = copyOfData
        }
      })
      .addCase(removeUser, (state, action) => {
        const id = action.payload
        const afterRemovedItem = state.data.filter(d => d.id !== id)
        state.data = afterRemovedItem
      })
      .addCase(getUser.pending, (state, action) => {
        state.selectedUser = null
        state.loading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = false
      })
      .addCase(addUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(updateUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedUser = {...state.selectedUser, ...action.payload}
        } 
        state.loading = false
      })
      .addCase(changeEmail.pending, (state, action) => {
        state.loading = true
      })
      .addCase(changeEmail.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload) {
          state.selectedUser.email = action.payload
        }
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedUser = null
        }
        state.loading = false
      })
      .addCase(changePassword.pending, (state, action) => {
        state.loading = true
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false
      })
  }
})

export default appUsersSlice.reducer
