// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
// Import Firebase
import { collection, query, where, doc, updateDoc, orderBy, getDocs, getDoc, deleteDoc, onSnapshot, getCountFromServer } from 'firebase/firestore'
import { firestore, firebaseAuth } from "@src/firebase"

// ** Axios Imports
import axios from 'axios'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment'

const API_BASEURL = process.env.REACT_APP_JOBS_FUNCTION_URL

const MySwal = withReactContent(Swal)
export const getOrgs = createAction('jobs/getOrgs')
export const getLocations = createAction('jobs/getLocations')
export const setJob = createAction('jobs/setJob')
export const modifyJob = createAction('jobs/updateJob')
export const removeJob = createAction('jobs/removeJob')
export const resetJob = createAction('jobs/resetJob')
export const setLoading = createAction('jobs/setLoading')
export const getPickList = createAction('jobs/getPickList')

export const getResources = createAsyncThunk('jobs/getResources', async(options, {getState, dispatch}) => {
  const user = getState().auth.userData
  // get organizations
  const orgSnapshots = await getDocs(
    query(
      collection(firestore, 'organisations'),
      where('tenant_id', '==', user.tenant_id)
    )
  )
  const orgData = orgSnapshots.docs.map(doc => ({...doc.data(), orgId: doc.id}))
  dispatch(getOrgs(orgData))

  // getLocations
  const locationSnapshots = await getDocs(
    query(
      collection(firestore, 'locations'),
      where('tenant_id', '==', user.tenant_id)
    )
  )

  const locationData = locationSnapshots.docs.map(doc => ({...doc.data(), id: doc.id}))
  dispatch(getLocations(locationData))

  // Get Pick List
    const pickListSnapshot = await getDoc(doc(firestore, 'pickLists', user.tenant_id))
    dispatch(getPickList(pickListSnapshot.data()))

  return true
})

export const getJobs = createAsyncThunk('appJobs/getJobs', async(options, {getState, dispatch}) => {
  const user = getState().auth.userData
  try {
    // const {date} = options
    const date = options?.date || moment().format("YYYY-MM-DD")
    // Get Jobs
    const queryConstrants = []
    if (date) {
      queryConstrants.push(where('scheduledOn', '==', date))
    }
    const q = query(
      collection(firestore, 'jobIDs'),
      where("tenant_id", "==", user.tenant_id),
      ...queryConstrants
    )
    dispatch(setLoading(true))
    onSnapshot(q, snapshots => {
      snapshots.docChanges().forEach(change => {
        if (change.type === 'modified') {
          dispatch(modifyJob(change.doc.data()))
        } else if (change.type === 'added') {
          dispatch(setJob(change.doc.data()))
        } else if (change.type === 'removed') {

        }
      })
    })

    setTimeout(() => {
      dispatch(setLoading(false))
    }, 2000)
  } catch (err) {
    console.log(err)
    return []
  }
})

export const getFavoriteFilters = createAsyncThunk('appJobs/getFavoriteFilters', async (options, {getState, dispatch}) => {
  const user = getState().auth.userData

  const q = query(
    collection(firestore, 'filterOptions'),
    where('filterType', '==', 'job'),
    where('userId', '==', user.uid),
    orderBy('lastUsed', 'desc')
  )

  const snapshots = await getDocs(q)

  const filterOptions = snapshots.docs.map(doc => {
    return {
      ...doc.data(),
      id: doc.id
    }
  })

  return filterOptions
})

export const updateJobStatus = createAsyncThunk('appJobs/updateJobStatus', async ({jobIDs, status}, {getState, dispatch}) => {
  try {
    const token = await firebaseAuth.currentUser.getIdToken()
    await axios.post(`${API_BASEURL}/updatejobstatus`, {jobIDs, status}, {headers : {
      Authorization: `Bearer ${token}`
    }})

    MySwal.fire({
      title: 'Success',
      text: 'Job status updated successfully!',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  } catch (error) {
    console.log(error)
    MySwal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  }

  return {jobIDs, status}
})

export const updateSpotCheckEnabled = createAsyncThunk('appJobs/updateSpotCheckEanble', async({jobID, spotStatus}, {getState, dispatch}) => {
  try {
    const token = await firebaseAuth.currentUser.getIdToken()
    await axios.post(`${API_BASEURL}/spotcheckrequired`, {jobID, spotCheckRequired: spotStatus}, {headers : {
      Authorization: `Bearer ${token}`
    }})

    MySwal.fire({
      title: 'Success',
      text: 'Job has been updated successfully!',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  } catch (error) {
    console.log(error)
    MySwal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  }
})

export const assignSpotCheckUser = createAsyncThunk('appJobs/assignSpotCheckUser', async({ jobID, userID, userName}, {getState, dispatch}) => {
  try {
    const token = await firebaseAuth.currentUser.getIdToken()
    await axios.post(`${API_BASEURL}/assignspotcheck`, {jobID, userID, userName}, {headers : {
      Authorization: `Bearer ${token}`
    }})

    MySwal.fire({
      title: 'Success',
      text: 'Spot Check has been assigned successfully!',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  } catch (error) {
    console.log(error)
    MySwal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  }
})

export const updateSpotCheck = createAsyncThunk('appJobs/updateSpotCheck', async({ jobID, spotcheckitem, stars}, {getState, dispatch}) => {
  try {
    const token = await firebaseAuth.currentUser.getIdToken()
    await axios.post(`${API_BASEURL}/updatespotcheck`, {jobID, spotcheckitem, stars}, {headers : {
      Authorization: `Bearer ${token}`
    }})

    // MySwal.fire({
    //   title: 'Success',
    //   text: 'Spot Check has been updated successfully!',
    //   icon: 'success',
    //   customClass: {
    //     confirmButton: 'btn btn-primary'
    //   }
    // })
  } catch (error) {
    console.log(error)
    MySwal.fire({
      title: 'Error!',
      text: 'Something went wrong!',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary'
      }
    })
  }
})


export const appJobsSlice = createSlice({
  name: 'appJobs',
  initialState: {
    jobs: [],
    params: {},
    organisations: [],
    locations: [],
    favoriteFilters: [],
    pickList: {},
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setLoading, (state, action) => {
        state.loading = action.payload
      })
      .addCase(getOrgs, (state, action) => {
        state.organisations = action.payload
      })
      .addCase(getLocations, (state, action) => {
        state.locations = action.payload
      })
      .addCase(resetJob, (state, action) => {
        state.jobs = []
      })
      .addCase(setJob, (state, action) => {
        if (state.jobs.findIndex(job => job.jobID === action.payload.jobID) === -1) {
          state.jobs = [...state.jobs, action.payload]
        }
      })
      .addCase(modifyJob, (state, action) => {
        const data = action.payload
        const index = state.jobs.findIndex(d => d.jobID === data.jobID)
        if (index > -1) {
          const copyOfData = [...state.jobs]
          copyOfData[index] = action.payload
          state.jobs = copyOfData
        }
      })
      .addCase(removeJob, (state, action) => {
        const id = action.payload
        const afterRemovedItem = state.jobs.filter(d => d.jobID !== id)
        state.jobs = afterRemovedItem
      })
      // .addCase(getJobs.pending, (state, action) => {
      //   state.loading = true
      // })
      // .addCase(getJobs.fulfilled, (state, action) => {
      //   state.jobs = action.payload
      //   state.loading = false
      // })
      .addCase(getFavoriteFilters.fulfilled, (state, action) => {
        state.favoriteFilters = action.payload
      })
      .addCase(updateJobStatus.pending, (state, action) => {
        state.loading = true
      })
      .addCase(updateJobStatus.fulfilled, (state, action) => {
        const jobs = [...state.jobs]
        const {jobIDs, status} = action.payload
        jobIDs.forEach(jobId => {
          const index = jobs.findIndex(job => job.jobID === jobId)
          if (index !== -1) {
            jobs[index].jobStatus = status
          }
        })
        state.jobs = jobs
        state.loading = false
      })
      .addCase(updateSpotCheckEnabled.pending, (state, action) => {
        state.loading = true
      })
      .addCase(updateSpotCheckEnabled.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(assignSpotCheckUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(assignSpotCheckUser.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(updateSpotCheck.pending, (state, action) => {
        state.loading = true
      })
      .addCase(updateSpotCheck.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(getPickList, (state, action) => {
        state.pickList = action.payload
      })
  }
})
  
export default appJobsSlice.reducer